import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
import { defaultTheme } from '@/config'
import { sortByAlphabetical } from '@/utils'
import { roleCode } from '@/config'

Vue.use(Vuex)

const vm = Vue.prototype

// 设置vuex持久化储存方式
const vuexLocal = new VuexPersistence({
  storage: window.sessionStorage,
  // 设置需要保存状态的属性
  reducer: (state) => (
    {
      navigationTags: state.navigationTags,
      currentTag: state.currentTag,
      currentTeamId: state.currentTeamId,
      currentCompany: state.currentCompany,
      user: state.user,
      userExt: state.userExt,
      token: state.token,
      menus: state.menus,
      companyList: state.companyList,
      roles: state.roles,
      shipList: state.shipList,
      permissions: state.permissions,
      bankList: state.bankList,
      chaserDefaultBank: state.chaserDefaultBank,
      companyDefaultBank: state.companyDefaultBank,
      creditAssociationList: state.creditAssociationList,
      currentCompanySettings: state.currentCompanySettings,
      currencyList: state.currencyList,
      currentCompanyUserList: state.currentCompanyUserList,
      locationList: state.locationList,
      productList: state.productList,
      productSpecificationList: state.productSpecificationList,
      vesselList: state.vesselList,
      creditAssociationsCompanyList: state.creditAssociationsCompanyList,
      mpaFuelTypeList: state.mpaFuelTypeList,
      isMobileScreen: state.isMobileScreen,
      physicalSupplierList: state.physicalSupplierList
    }
  )
})
const currentTheme = defaultTheme
const state = {
  // 导航标签列表
  navigationTags: [],
  // 当前导航标签页
  currentTag: {},
  // 当前团队ID
  currentTeamId: null,
  // 用户所在当前企业
  currentCompany: {},
  currentCompanySettings: {},
  // 用户对象
  user: {},
  userExt: {},
  token: undefined,
  // 菜单列表
  menus: [],
  // 用户所在企业列表
  companyList: [],
  currentTheme,
  // 当前用户的角色
  roles: [],
  // 当前用户的角色的权限(暂时使用，待permission模块完善后更改)
  permissions: {},
  // 船列表
  shipList: [],
  // 银行账户列表 bank account list
  bankList: null,
  chaserDefaultBank: null,
  companyDefaultBank: null,
  creditAssociationList: null,
  currencyList: null,
  currentCompanyUserList: null,
  locationList: null,
  productList: null,
  productSpecificationList: null,
  vesselList: null,
  financeAccountMe: null, // set financer or supplier organization info
  mpaFuelTypeList: [],
  isMobileScreen:false,
  physicalSupplierList:[]
}

const initialState = JSON.parse(JSON.stringify(state))

export default new Vuex.Store({
  state,
  mutations: {
    // 所有mutation需命名为set+state(首字母大写)的格式,以便重置state
    setUser (state, value) {
      state.user = value
    },
    setUserExt (state, value) {
      state.userExt = value
    },
    setCurrentTeamId (state, value) {
      state.currentTeamId = value
    },
    setCompanyList (state, value) {
      state.companyList = value
    },

    setCurrentCompany (state, value) {
      state.currentCompany = value
    },
    setCurrentCompanySettings (state, value) {
      state.currentCompanySettings = value
    },
    setNavigationTags (state, value) {
      state.navigationTags = value
    },
    setCurrentTag (state, value) {
      state.currentTag = value
    },
    setMenus (state, value) {
      state.menus = value
      // localStorage.currentTheme = JSON.stringify(value)
    },
    setCurrentTheme (state, value) {
      state.currentTheme = value
    },
    setToken (state, value) {
      state.token = value
    },
    setRoles (state, value) {
      state.roles = value
    },
    setPermissions (state, value) {
      const permission = {}

      if (value.length > 0) {
        permission.hasSysAdminPermission = value.includes(roleCode.systemAdmin)
        permission.hasCompanyAdminPermission = value.includes(roleCode.companyAdmin)
        permission.hasCompanyManagerPermission = value.includes(roleCode.companyManager)
        permission.hasCompanyAccountsPermission = value.includes(roleCode.accounts)
        permission.hasShipPermission = value.includes(roleCode.ship)
      }
      state.permissions = { ...permission }
    },
    setShipList (state, value) {
      state.shipList = value
    },
    setBankList (state, value) {
      state.bankList = value
    },
    setChaserDefaultBank (state, value) {
      state.chaserDefaultBank = value
    },
    setCompanyDefaultBank (state, value) {
      state.companyDefaultBank = value
    },
    setCreditAssociationList (state, value) {
      state.creditAssociationList = value ? sortByAlphabetical(value, 'counterpartyCompanyName') : value
    },
    setCurrencyList (state, value) {
      state.currencyList = value
    },
    setCurrentCompanyUserList (state, value) {
      state.currentCompanyUserList = value
    },
    setLocationList (state, value) {
      state.locationList = value ? sortByAlphabetical(value, 'locationCode') : value
    },
    setProductList (state, value) {
      state.productList = value ? sortByAlphabetical(value, 'name') : value
    },

    setProductSpecificationList (state, value) {
      state.productSpecificationList = value
    },
    setVesselList (state, value) {
      state.vesselList = value ? sortByAlphabetical(value, 'shipName') : value
    },
    setCreditAssociationsCompanyList (state, value) {
      state.creditAssociationsCompanyList = value ? sortByAlphabetical(value, 'counterpartyCompanyName') : value
    },
    setFinanceAccountMe (state, value) {
      state.financeAccountMe = value
    },
    setMpaFuelTypeList (state, value) {
      state.mpaFuelTypeList = value ? sortByAlphabetical(value, 'label') : value
    },
    setIsMobileScreen (state, value) {
      state.isMobileScreen = value;
    },
    setPhysicalSupplierList (state, value) {
      state.physicalSupplierList = value
    },

  },

  actions: {
    resetState ({ commit }) {
      /*
       * 重置状态数据，供退出登录使用
       */
      for (const key in initialState) {
        if (Object.prototype.hasOwnProperty.call(initialState, key)) {
          const mutation = 'set' + key.replace(/^[a-z]/g, l => l.toUpperCase())
          commit(mutation, initialState[key])
        }
      }
    },
    // resetCurrentCompany ({ commit }) {
    //   commit('setCurrentCompany', {})
    // },
    setCurrentCompany ({ commit }, value) {
      commit('setCurrentCompany', value)
    },
    setCurrentCompanySettings ({ commit }, value) {
      commit('setCurrentCompanySettings', value)
    },
    async getShipList ({ commit }, { pageNumber = 1, pageSize = 999999, organizationId = null }) {
      return await vm.$request.get({
        url: `${vm.$apis.shipBaseUrl}?pageNumber=${pageNumber}&pageSize=${pageSize}` + (organizationId ? `&organizationId=${organizationId}` : '')
      }).then(res => {
        if (res?.code === 1000) {
          commit('setShipList', res.data.records || [])
        }
      })
    },
    /*
      如果是系统管理员后台才去check id，如果不是系统管理，后台直接返回当前公司的数据
      If it is the system administrator, backend will checked id, if not backend will return the data of the current company.
    */
    getBankList ({ commit }, id) {
      let url = vm.$apis.banksUrl
      if (id) { url = `${vm.$apis.banksUrl}?sysOrganizationId=${id}` }
      return vm.$request.get({
        url
      }).then(res => {
        if (res?.code === 1000) {
          const isdefault = res.data && res.data.length > 0 && res.data.filter(item => item.isDefault)
          let companyDefaultBank = null
          let chaserDefaultBank = null
          isdefault && isdefault.length > 0 && isdefault.forEach(e => {
            if (e.bankType === 'COMPANY_BANK') {
              companyDefaultBank = e
            }
            if (e.bankType === 'CHASER_BANK') {
              chaserDefaultBank = e
            }
          })
          commit('setBankList', res.data || [])
          commit('setCompanyDefaultBank', companyDefaultBank || {})
          commit('setChaserDefaultBank', chaserDefaultBank || {})
        }
      })
    },
    getCreditAssociationList ({ commit }) {
      return vm.$request.get({
        url: `${vm.$apis.creditAssociations}?pageNumber=0&pageSize=9999`
      }).then(res => {
        if (res?.code === 1000) {
          commit('setCreditAssociationList', res.data.content || [])
        }
      })
    },
    getCurrencyList ({ commit }) {
      return vm.$request.get({
        url: vm.$apis.sysCurrencies
      }).then(res => {
        if (res?.code === 1000) {
          commit('setCurrencyList', res.data || [])
        }
      })
    },
    getCurrentCompanyUserList ({ commit }, { orgId }) {
      vm.$request.get({
        url: `${vm.$apis.userExtBaseUrl}?organizationId=${orgId}`,
      }).then(res => {
        if (res?.code === 1000) {
          const userList = (res?.data?.records || [])
          commit('setCurrentCompanyUserList', userList || [])
        }
      })
    },
    getLocationList ({ commit }) {
      return vm.$request.get({
        url: vm.$apis.location
      }).then(res => {
        if (res?.code === 1000) {
          const list = res.data
          list.forEach(item => {
            item.children.forEach(cItem => {
              if (cItem.children.length < 1) {
                delete cItem.children
              }
            })
          })
          commit('setLocationList', list || [])
        }
      })
    },
    setLocationList ({ commit }, payload) {
      return vm.$request.post({
        url: vm.$apis.location,
        data: payload
      }).then(res => {
        if (res?.code === 1000) {
          commit('setLocationList', res.data || [])
        }
      })
    },
    getProductList ({ commit }) {
      return vm.$request.get({
        url: `${vm.$apis.products}`
      }).then(res => {
        if (res?.code === 1000) {
          commit('setProductList', res.data || [])
          return res.data
        }
      })
    },
    getProductSpecificationList ({ commit }) {
      return vm.$request.get({
        url: `${vm.$apis.productSpecifications}`
      }).then(res => {
        if (res?.code === 1000) {
          commit('setProductSpecificationList', res.data || [])
          return res.data
        }
      })
    },
    getVesselList ({ commit }) {
      return vm.$request.get({
        url: `${vm.$apis.shipBaseUrl}?pageNumber=1&pageSize=999999`
      }).then(res => {
        if (res?.code === 1000) {
          commit('setVesselList', res.data.records || [])
        }
      })
    },
    getCompanySettings ({ commit }, companyId) {
      return new Promise((resolve, reject) => {
        vm.$request.get({ url: `app/sysOrganization/${companyId}/settings` }).then(res => {
          if (res?.code === 1000 && res?.data) {
            commit('setCurrentCompanySettings', res.data)
          }
          resolve(res)
        }).catch(e => { reject(e) })
      })
    },
    getMpaFuelTypeList ({ commit }) {

      return vm.$request.get({
        url: `${vm.$apis.mpaBunkerFuelTypes}`
      }).then(res => {
        if (res?.code === 1000) {
          const arr = res.data
          ? res.data.map((type) => ({
              id:type.id,
              bunkerType:type.bunkerType,
              fuelTypeCode:type.fuelTypeCode,
              label: `${type.fuelTypeDescription} (${type.fuelTypeCode})`,
              fuelTypeDescription: type.fuelTypeDescription,
              value: type.fuelTypeCode,
              oilType: type?.oilType??null,
              updatedAt: type.updatedAt,

            }))
          : []
          commit('setMpaFuelTypeList', arr)
          return arr;
        }
      })
    },
    getFilterMpaFuelTypeList ({ commit },data) {
      const {oilType,shipId} =data
      const q = data.shipId?`bargeShipId=${shipId}&oilType=${oilType}`:`oilType=${oilType}`
      return vm.$request.get({
        url: `/mpa/bunker_fuel_types/by_oil_type?${q}`
      }).then(res => {
        if (res?.code === 1000) {
          const arr = res.data
          ? res.data.map((type) => ({
              id:type.id,
              label: `${type.fuelTypeDescription} (${type.fuelTypeCode})`,
              value: type.fuelTypeCode,
              bunkerType:type.bunkerType,
              fuelTypeCode:type.fuelTypeCode,
              fuelTypeDescription: type.fuelTypeDescription,
              oilType: type?.oilType??null,
              updatedAt: type.updatedAt,
            }))
          : []
          return arr;
        }
      })
    },
    isExxonMobil({ commit }, companyId) {
      return new Promise((resolve, reject) => {
        vm.$request.get({ url: `app/sysOrganization/${companyId}/settings` }).then(res => {
          if (res?.code === 1000 && res?.data) {
            resolve(res?.data?.isExxonMobil ?? false)
          }
          resolve(false)
        }).catch(e => { resolve(false) })
      })
    },
    getPhysicalSupplierList ({ commit }) {
      return vm.$request.get({
        url: `${vm.$apis.sharedCompanyBaseUrl}?pageNumber=1&pageSize=9999&physicalSupplier=true`
      }).then(res => {
        if (res?.code === 1000) {
         const arr =res.data?.records??[]
          commit('setPhysicalSupplierList', arr)
          return arr
        }
      })
    },
  },
  modules: {},
  plugins: [vuexLocal.plugin]
})
