/**
 * @description axios请求封装
 * @author One
 * @date 2020/11/30
 */

import axios from 'axios'
import apis from './apis'
import store from '@/store/index'
import { router } from '@/router'
import { Message } from 'element-ui'
import md5 from '@/utils/md5'
import i18n from '@/i18n'

// 请求集合,用于储存正在进行的请求的uuid
let ongoingRequestSet = new Set()

/**
 * 创建单次请求的uuid
 * @param {Object} config:请求设置
 */
const createRequestUuid = config => {
  const { url, method } = config
  let { data } = config
  if (data instanceof Object) {
    data = JSON.stringify(data)
  }
  const str = url + method + data
  return md5(str)
}

/**
 * 判断请求集合里面是否有本次请求的uuid
 * @return {Boolean}
 */
const hasUuid = uuid => {
  if (ongoingRequestSet.has(uuid)) {
    return true
  } else {
    // 没有则添加
    ongoingRequestSet.add(uuid)
    return false
  }
}

/**
 * 删除请求集合里面本次请求的uuid
 */
const deleteUuid = config => {
  const uuid = createRequestUuid(config)
  ongoingRequestSet.delete(uuid)
}

const request = axios.create(
  {
    baseURL: apis.baseUrl,
    timeout: 1000 * 60,
    header: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    withCredentials: true
  }
)

// 请求拦截器
request.interceptors.request.use(
  config => {
    const uuid = createRequestUuid(config)
    if (hasUuid(uuid)) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject({
        response: {
          data: {
            msg: i18n.t('duplicateRequestTip')
          }
        }
      })
    }
    const token = store.state.token
    token && (config.headers.token = token)
    if (config.params) {
      config.params.lang = 'en_US'
    } else {
      config.params = {
        lang: 'en_US'
      }
    }
    return config
  },
  error => {
    // 提示错误信息
    Message.error(error.toString())
    return Promise.reject(error)
  })

// response响应拦截器
request.interceptors.response.use(
  response => {
    // 清除请求集合里面的本次请求的uuid
    deleteUuid(response.config)
    // 获取状态码，此状态码和后端约定，此例中0表示成功，1表示失败
    const code = response.data?.code
    // 如果状态码是2009或2010，则认证失败，跳转登录页
    if ([2009, 2010].includes(code)) {
      store.commit('setToken', '')
      return router.replace({
        name: 'login'
      })
    }
    // 如果有状态码但不是1000,则提示错误信息
    if (code && code !== 1000) {
      const msg = response.data.msg || response.data.message || i18n.t('requestError')

      return Message.error(msg)
    }
    return response.data
  },
  error => {
    const { config } = error
    // 清除请求集合里面的本次请求的uuid
    if (config) {
      deleteUuid(config)
    } else {
      ongoingRequestSet = new Set()
    }
    const errorMsg = i18n.t('requestError')
    const data = error?.response?.data
    const resMsg = data?.msg || data?.message
    Message.error(resMsg || errorMsg)
    return Promise.reject(error)
  }
)

// 封装get请求
const get = ({ url, params }) => {
  return request.get(url, {
    params: params
  })
}

// 封装非上传文件的post请求
const post = ({ url, data }) => {
  return request.post(url, data)
}

// 封装put请求
const put = ({ url, data }) => {
  return request.put(url, data)
}

const delete_request = (url, data) =>{

  let fullUrl = `${apis.baseUrl}${url}`
  return request.delete(fullUrl, { data: data })
}


// 上传文件的post请求
const postFormData = ({ url, data = {} }) => {
  const formData = new FormData()
  Object.keys(data).forEach(key => {
    // 如果是数组，则需要拆分重复添加
    if (Array.isArray(data[key])) {
      data[key].forEach(item => {
        formData.append(key, item)
      })
    } else {
      formData.append(key, data[key])
    }
  })
  return request.post(url, formData,
    {
      headers: { 'Content-Type': 'multipart/form-data;charset=UTF-8' }
    }
  )
}

// 封装文件下载
const getFile = ({ url, data }) => {
  return request.post(
    url,
    data,
    {
      responseType: 'blob',
      // 请求超时30分钟
      timeout: 30 * 60 * 1000
    }
  )
}

export default {
  get,
  post,
  put,
  delete: delete_request,
  postFormData,
  getFile
}
