/**
 * @Description 后端api接口目录,需要挂载到 Vue原型
 */

// 外网测试环境
const baseUrl = process.env.VUE_APP_BASE_API_URL + "/"
const websocketBaseUrl = process.env.VUE_APP_WEBSOCKET_BASE_API_URL

export default {
  // ip,
  baseUrl,
  websocketBaseUrl,

  // 验证码
  getCaptchaImage: 'kaptcha/render',

  // 企业注册
  registerCompany: 'app/sysOrganization/register',

  // 登录及登出接口
  login: 'app/sysUser/login',
  logout: 'app/sysUser/logout',

  // 邮箱校验
  sendEmail: 'app/email/send',
  verifyEmail: 'app/email/verify',
  sendEmailInternal: 'app/email/send_internal',

  // 菜单管理接口
  saveMenu: 'app/sysMenu/save',
  queryMenu: 'app/sysMenu/query',
  deleteMenu: 'app/sysMenu/delete',
  queryMenuTree: 'app/sysMenu/tree',
  queryMenuTreeMenu: 'app/sysMenu/treeMenu',

  // 企业管理接口
  companyBaseUrl: 'app/sysOrganization',
  sharedCompanyBaseUrl: 'shared/organizations',
  saveCompany: 'app/sysOrganization/save',
  deleteCompany: 'app/sysOrganization/delete',
  inviteCompany: 'app/sysOrganization/inviteOrgRegister',
  submitUpdateCompany: 'app/approvalInfo/launchOrgApprovalInfo',
  auditUpdateCompany: 'app/approvalInfo/auditOrgApprovalInfo',
  queryAuditHistory: 'app/approvalInfo/query',
  enableOrDisableCompany: 'app/sysOrganization/applyApproval',
  getCompanyInfoByUuid: 'app/sysOrganization/getParamByUUid',
  banksUrl: '/app/banks',

  // 团队管理接口
  teamBaseUrl: 'app/sysTeam',
  saveTeam: 'app/sysTeam/save',
  deleteTeam: 'app/sysTeam/delete',

  // 角色管理接口
  roleBaseUrl: 'app/sysRole',
  saveRole: 'app/sysRole/save',
  deleteRole: 'app/sysRole/delete',
  internalRoleCodes: '/app/sysRole/internal_codes',

  // 用户管理接口
  saveUser: 'app/sysUser/save',
  queryUser: 'app/sysUser/query',
  deleteUser: 'app/sysUser/delete',
  inviteUser: 'app/sysUser/inviteUserRegister',
  saveInviteUserRegister: 'app/sysUser/saveInviteUserRegister',
  getInviteUserParamByUUid: 'app/sysUser/getParamByUUid',
  changeEmail: 'app/sysUser/modifyEmail',
  resetPassword: 'app/sysUser/resetPassword',
  userExtBaseUrl: 'app/sysUserExt',
  sharedUserBaseUrl: 'shared/users',
  saveUserNew: 'app/sysUserExt/save',
  deleteUserNew: 'app/sysUserExt/delete',

  // 功能点管理接口
  saveFunction: 'app/sysFunction/save',
  queryFunction: 'app/sysFunction/query',
  deleteFunction: 'app/sysFunction/delete',
  bindFunction: 'app/sysFunction/addFunctionToMenu',
  unbindFunction: 'app/sysFunction/removeFunctionFromMenu',

  // 权限管理接口
  permissionBaseUrl: 'app/sysPermission',
  savePermission: 'app/sysPermission/save',
  batchSavePermission: 'app/sysPermission/batchSavePermission',
  deletePermission: 'app/sysPermission/delete',

  // 船舶管理接口
  shipBaseUrl: 'app/ship',
  saveShip: 'app/ship/save',
  editShip: 'app/ship/edit',
  deleteShip: 'app/ship/delete',
  uploadShipStamp: 'app/ship/upload_stamp',
  saveInvitationShip: 'app/ship/saveInvitationRegister',
  getInviteShipParamByUUid: 'app/ship/getParamByUUid',
  inviteShip: 'app/ship/invitationShipRegister',
  getAvailableBarges: '/app/ship/getAvailableBarges',

  // 日志接口
  queryRequestLog: 'app/sysLog/query',
  queryDataChangeLog: 'app/businessDataLog/query',
  queryMobileAppLog: 'mobileApp/logs',

  // 文件管理接口
  uploadFile: 'app/sysFile/upload',
  queryFile: 'app/sysFile/query',
  downloadFile: 'app/sysFile/download',
  getFileUrl: 'app/sysFile/getFileUrl',
  deleteFile: 'app/sysFile/delete',

  // 企业注册时校验各种数据
  validateDuplicateData: 'app/sysUser/validate',

  // 合同管理
  //TODO should check for duplicates (end point 'orders')
  orders: '/app/contract',
  saveContract: 'app/contract/save',
  saveContractAssEntity: 'app/contract/saveAssEntity',
  linkContract: 'app/contract/associate',
  queryContract: 'app/contract/query',
  deleteContractById: 'app/contract/delete',
  sendEmailOfflineContract: 'app/contract/sendEmailOfflineContract',
  getParamByUUid: 'app/contract/getParamByUUid',
  saveContractByState: 'app/contract/changeState',
  postedListApproval: '/app/contract/postedListApproval',
  verifyContractCA: '/app/contract/isCreditAuthorizationRequired',

  // nomination/job
  queryNomination: 'app/exwharfNominationJob/query',
  saveNomination: 'app/exwharfNominationJob/save',
  deleteNomination: 'app/exwharfNominationJob/delete',
  editNomination: 'ebdn/jobs/editNomination',
  editExWharfJob: 'ebdn/jobs/editExwharf',
  editDeliveredJob: 'ebdn/jobs/editDeliveredSts',
  createNewJob: 'ebdn/jobs',
  sendEmailInfo: 'app/exwharfNominationJob/sendEmailInfo',

  // 生成pdf
  createPdf: 'app/contract/genContract',
  // 国际化接口
  // queryI18n: utilServeBaseUrl + 'app/i18n/query'
  // 订单链
  orderChain: 'app/contract/trace',

  // Price Method
  methodPrice: 'methodologyPrices',
  methodologies: 'methodologies',
  getMethodologyByCompanyId: 'methodologies/companies',

  // Calendar
  getCalendarByYear: 'app/calendars/years',
  calendar: 'app/calendars',

  // Product
  products: '/app/products',
  recentProducts: '/app/products/recent',
  productSpecifications: '/app/product_specifications',

  // Order and terms
  orders: '/orders',
  verifyOrder: '/orders/verify_item',
  verifyOrderCA: '/orders/isCreditAuthorizationRequired',
  linkUnlinkOrder: '/orders/link_unlink',
  offlineOrder: '/orders/getDataByUUID',
  termContract: '/term_contracts',
  checkBargeAvailability: '/term_contracts/checkBargeAvailability',

  // Posted List
  postedListAuthorizedSignatoryApproval: '/orders/posted_list/authorized_signatory_approval',
  postedListAccountApproval: '/orders/posted_list/account_approval',

  // Invoice
  invoices: '/invoices',
  authSignatoryApproval: '/invoices/authorized_signatory_approval',
  traderApproval: '/invoices/trader_approval',
  verifyInvoiceCA: '/invoices/isCreditAuthorizationRequired',

  // Finacing
  finance: '/finance',

  // Credit or Debit Notes
  cdNotes: '/invoice/CreditDebitNotes',
  batchApproveCdNotes: '/invoice/CreditDebitNotes/authorized_signatory_approval',
  verifyCdNoteCA: '/invoice/CreditDebitNotes/isCreditAuthorizationRequired',

  // Payment
  payments: '/invoice/payments',
  receipts: '/invoice/receipts',
  calculatePaymentInfo: '/invoice/payments/calculatePaymentInformation',
  // linkInvoice: '/invoice/{invoiceId}/statements',
  // unlinkInvoice: '/invoice/{invoiceId}/statements/{statementId}/unlink',
  // createStatement: '/invoice',
  // editStatement: '/invoice',
  // deleteStatement: '/invoice'

  // Currency
  sysCurrencies: '/app/currencies',
  sysCurrencyByCode: '/app/currencies/code',

  // Job
  getProductList: '/app/sysProductNames',
  getBalanceNumber: '/ebdn/jobs/order/',
  getJobInfoByProductIds: '/ebdn/jobs/getJobInfoByProductIds',
  getJobInfoByOrder: '/ebdn/jobs/getJobInfoByOrder',

  // verify
  verifyProducts: '/ebdn/jobs/verifyProducts',
  verifyOtherCosts: '/ebdn/jobs/verifyOtherCost',
  searchAssociation: '/ebdn/jobs/productAssoc',



  // Credit Association
  creditAssociations: '/credit_associations',
  creditAssociationsRequest: '/credit_associations/request',
  creditAssociationsUpdateCreditInformation: '/credit_associations/updateCreditInformation',

  // Customer
  postOnlineCounterparty: '/credit_associations/request/online_counterparty',
  postOfflineCounterparty: '/credit_associations/request/offline_counterparty',

  // Credit Authorization Request
  creditAuthorizationRequests: '/credit_authorization_requests',
  creditAuthorizationRequestsApproval: '/credit_authorization_requests/approval',

  // Offline Sys Organization
  offlineOrganization: '/app/sysOrganization/offline',
  offlineOrganizationContact: '/app/offline_organization_contacts',

  // loactions
  // location: 'ebdn/locations',
  location: '/app/locations',

  // BDN License Authorization
  bdnLicenseAuthorization: '/bdn_license_authorizations',
  bdnLicenseAuthorizationAuthorize: '/bdn_license_authorizations/authorize',
  bdnLicenseAuthorizationDeauthorize: '/bdn_license_authorizations/deauthorize',

  // Cargo Officer
  cargoOfficer: '/cargo_officers',

  mpaBunkerLocation: '/mpa/bunker_locations',
  mpaBunkerFuelTypes: '/mpa/bunker_fuel_types',
  mpaReceivingVessels: '/mpa/receiving_vessels',
  searchReceivingVessels: '/mpa/search_receiving_vessels',
  mpaBiofuelGenCodes: '/mpa/biofuel_gen_codes',
  recentMpaBunkerFuelTypes: '/mpa/recent_fuel_types',

  meterTotalizerLogs: '/meter_totalizer_logs',
  // {{base_url}}/app/ship/transferLog?pageNumber=0&pageSize=10&filter=tenantId:%271764529693253095424%27
  shipTransferLogs: '/app/ship/transferLog',
  shipTransfer: '/app/ship/transfer',
  shipTerminate: '/app/ship/terminate',
  // {{base_url}}/cargo_officers/transferLog?pageNumber=0&pageSize=10&filter=organizationId:%271764529693253095424%27
  cargoOfficerTransferLogs: '/cargo_officers/transferLog',
  cargoOfficerTransfer: '/cargo_officers/transfer',
  cargoOfficerTerminate: '/cargo_officers/terminate',

  clientApps: '/api/v1/client-apps',

  // Pricing Tier
  pricingTierDef: '/pricingTierDef',
  pricingTier: '/pricingTier',

  pricingTierMonthly: '/pricingTier/monthly',
  pricingTierSubmitTiers: '/pricingTier/submitTiers',

  // Customer Pricing Tier
  creditAssociationsCustomerTiers: '/credit_associations/customerTier',
  creditAssociationsUpdatePricingTier: '/credit_associations/updatePricingTier',

  // /victory/getCustomer
  getVictoryCustomer:'/victory/getCustomer',
  getVictoryAccountSet:'/victory/getAccountSet',
  getVictoryBillingCycle:'/victory/getBillingCycle',
  getVictoryTerms:'/victory/getTerms',

  bindedBargeAndLoaction:'/orders/selfCollectKiosk',
  // Dashboard
  jobSummaryCompletedJobs: '/job-summary/completed-jobs',
  jobSummaryOilTypeQuantities: '/job-summary/oil-type-quantities',
  jobSummaryLetterOfProtest: '/job-summary/letter-of-protest',
  jobSummaryCompletedVessels: '/job-summary/completed-vessels',
  jobSummaryCompletedBarges: '/job-summary/completed-barges',
  getEbdnMfmItemBarge: '/ebdn/mfmBarge/items', //ebdn/mfmBarge/items?shipId=$bargeId
  postEbdnMfmItemBarge: '/ebdn/mfmBarge/save', //ebdn/mfmBarge/items?shipId=$bargeId

}
